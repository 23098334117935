import React from 'react';

import GlobalStyles from '../global/styles';
import SEO from "../components/seo";

import ContactClient from '../components/Clients';


function Assinar() {
  return(
    <>
      <GlobalStyles />
      <ContactClient />
      <SEO title="Assinatura" />
    </>
  );
}

export default Assinar;