import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ContactFormSection = styled.div`
    padding: 0 50px 0 50px;

    h2 {
        padding-bottom: 15px;
        color: var(--text-color-terciary);
    }

    .validation {
        padding: 5px 0 10px;
        font-size: 14px;
        color: #E14A58;
        font-weight: bold;
    }

    .input-section {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .input-section span {
        padding-bottom: 10px;
        color: var(--text-color-quartenary);
        font-weight: 600;
    }

    .input-section input, .input-section textarea, .input-section select {
        height: 35px;
        padding-left: 10px;
        border: 1px solid #A0A0A0;
        border-radius: 5px;
        resize: none;
        color: var(--text-color-quartenary);

        &:focus {
            border: 2px solid var(--color-secondary);
        }
    }

    .input-section textarea {
        height: 70px;
        padding-top: 10px;
    }

    .terms {
        display: flex;
        align-items: center;
        padding: 15px 0 15px 0;
        
        p {
            margin-left: 10px;
            font-size: 14px;
            color: var(--text-color-quartenary);
        }

        a {
            margin-left: 5px;
            font-size: 14px;
            color: var(--color-secondary);
            transition: 0.5s;
            font-weight: bold;
            &:hover {
                color: #A3D95A;
            }
        }
    }
    
    button {
        width: 100%;
        height: 50px;
        margin-top: 20px;
        margin-bottom: 40px;
        border: none;
        border-radius: 10px;
        background-color: var(--color-secondary);
        color: var(--color-white);
        font-weight: 600;
        font-size: 17px;
        transition: 0.5s;

        &:hover {
            background-color: var(--color-primary);
        }
    }

    @media (max-width: 571px) {
        padding: 0 60px 50px 60px;
    }

    @media (max-width: 449px) {
        padding: 0 40px 50px 40px;
    }

    @media (max-width: 360px) {
        padding: 0 30px 50px 30px;
    }

    @media (max-width: 320px) {
        padding: 0 20px 50px 20px;
    }
`; 