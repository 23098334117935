import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1250px) {
        flex-direction: column-reverse;
    }
`;

export const ContactWrapper = styled.div`
    background-color: var(--color-primary);
    height: 100vh;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    box-shadow: 4px 4px 10px 4px rgba(0,0,0,0.2);

    > img {
        padding-top: 80px;
    }

    a {
        position: relative;
        right: 40%;
        bottom: 20px;
    }

    .icon_back {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        top: 15px;

        span {
            color: var(--color-white);
            padding-left: 15px;
        }
    }

    @media (max-width: 1250px) {
        width: 100%;
        .icon_back {
            top: 30px;
        }
    }

    @media (max-width: 571px) {
        > img {
            padding-top: 60px;
            width: 450px;
        }
    }

    @media (max-width: 449px) {
        > img {
            width: 400px;
        }
    }

    @media (max-width: 449px) {
        .icon_back {
            left: 15px;
        }
    }

    @media (max-width: 360px) {
        > img {
            width: 350px;
        }
    }

    @media (max-width: 320px) {
        > img {
            width: 300px;
        }
    }
`;

export const ContactWrapperText = styled.div`
    text-transform: capitalize;
    padding-top: 30px;
    color: var(--color-white);
    font-weight: 700;

    h1 {
        font-size: 22px;
        color: #EDEDED;
    }

    @media (max-width: 571px) {
        padding-top: 50px;
    }

    @media (max-width: 449px) {
        font-size: 14px;
    }

    @media (max-width: 320px) {
        font-size: 11px;
    }
`;

export const ContactForm = styled.div`
    background-color: var(--color-white);
    height: 100vh;
    overflow: auto;
    flex: 1;

    box-shadow: 2px 2px 10px 2px rgba(0,0,0,0.2);

    @media (max-width: 1250px) {
        width: 100%;
    }
`;

export const ContactFormText = styled.div`
    padding: 40px 50px 40px 50px;

    h1 {
        color: var(--text-color-terciary);
        padding-bottom: 10px;
    }

    p {
        color: var(--text-color-quartenary);
        font-size: 17px;
    }

    .radio-button{
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 300px;

        label {
            padding-left: 10px;
            color: var(--text-color-quartenary);
        }

        .radio {
            display: flex;
            align-items: center;
            input {
                width: 20px;
                height: 20px;
                cursor: pointer;
                background-color: var(--color-secondary);
            }

            input:checked {
                background-color: #d1d3d1;
            }
        }
    }

    @media (max-width: 571px) {
        padding: 50px 60px 50px 60px;
    }

    @media (max-width: 449px) {
        padding: 50px 40px 50px 40px;
    }

    @media (max-width: 360px) {
        padding: 50px 30px 50px 30px;
        .radio-button {
            font-size: 14px;
            width: 270px;
        }
    }

    @media (max-width: 320px) {
        padding: 50px 20px 50px 20px;
    }
`;



