import React, { useState, useEffect } from 'react';

import LegalClient from './ LegalClient/index';
import PhysicalClient from './PhysicalClient/index';

import { 
    Container, 
    ContactWrapper, 
    ContactWrapperText, 
    ContactForm, 
    ContactFormText 
} from './styles';
import ContactClients from '../../images/Clients/scrum_board.svg';
import { MdKeyboardBackspace } from 'react-icons/md';

import api from '../../services/api';

function Clients() {
  const [radio, setRadio] = useState('Juridica');

  const [plans, setPlans] = useState([]);

  useEffect(()=>{
    async function getPlans(){
      const response = await api.get('https://api.portal.biplan.com.br/plans');
      setPlans(response.data);
    }

    getPlans();
  },[])

  return(
    <Container>
        <ContactForm>
            <ContactFormText>
               <div className="title">
                  <h1>Registre-se!</h1>
                  <p>Preencha o formulário abaixo com suas informações.</p>
               </div>
               <div className="radio-button">

                  <div className="radio">
                    <input type="radio" 
                    checked={radio === 'Juridica'}
                    value="Juridica"
                    onChange={(e) => {setRadio(e.target.value)}}/>
                    <label htmlFor="juridica">Pessoa Jurídica</label>
                  </div>

                   <div className="radio">
                      <input type="radio" 
                      checked={radio === 'Fisica'}
                      value="Fisica"
                      onChange={(e) => {setRadio(e.target.value)}}/>
                      <span className="checkmark"></span>
                      <label htmlFor="fisica">Pessoa Física</label>
                   </div>
               </div>
            </ContactFormText>
            {radio === 'Fisica' ? <LegalClient plans={plans}/> : <PhysicalClient plans={plans}/> }  
        </ContactForm>
        <ContactWrapper>
            <a href="/">
              <div className="icon_back">
                <MdKeyboardBackspace size="2em" color="#fff"/>
                <span>Voltar</span>
              </div>
            </a>
            <ContactWrapperText>
              <h1>Para liberar seu plano, cadastre-se na nossa plataforma!</h1>
            </ContactWrapperText>
            <img src={ContactClients} alt="" width="650"/>
        </ContactWrapper>
    </Container>
  );
}

export default Clients;