import React, {  useState } from 'react';

import { Wrapper, ContactFormSection } from './styles';
import api from '../../../services/api';

import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import InputMask from "react-input-mask";

import { useQueryParam, NumberParam } from "use-query-params";

function LegalClient({plans}) {

  const [planId] = useQueryParam("planId", NumberParam);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [document, setDocument] = useState('');
  const [plan, setPlan] = useState(planId ? planId : 1);

  const [passwordValidate, setPasswordValidate] = useState('');

  const [isChecked, setIsChecked] = useState(false);

  var user = {};
  user.name = name;
  user.email = email;
  user.password = password;

  var company = {};
  company.name = companyName;
  company.document = document;

  var req = {};
  req.user = user;
  req.company = company;
  req.plan = plan;

  const insert = async () => {


      if(password.length < 6) {
        setPasswordValidate('A senha precisa ter no mínimo 6 caracteres.')
      }
      else {
        setPasswordValidate('')
      }

      if(isChecked) {
          api.post('/landingpage', req)
          .then(response => { 
            window.location.assign('https://portal.equiperemota.com.br/');
          })
          .catch(error => {
            if(error.response.data.error === 'Validação para Usuário falhou') {
              toast.error('Preencha todos os campos.');
            }
            if(error.response.data.error === 'Já existe um usuário com esse email') {
              toast.error('Já existe um usuário com esse e-mail.');
            }
            if(error.response.data.error === 'Validação para Empresa falhou') {
              toast.error('Preencha todos os campos.');
            }
            if(error.response.data.error === 'Já existe uma empresa com esse cpf/cnpj') {
              toast.error('Já existe uma empresa cadastrada com esse cpf/cnpj.');
            }
          })
        }
        else {
          toast.error('É necessário aceitar os termos.');
          toast.error('Preencha os Campos.');
        }
      }

  return(
    <Wrapper>
      <ToastContainer />
      <ContactFormSection>
        <form>
          <h2>Crie uma conta!</h2>
          <div className="input-section">
            <span>Nome</span>
            <input 
            type="text" 
            name="name" 
            value={name} 
            onChange={e => setName(e.target.value)} 
            placeholder="Digite seu nome completo" required
            />
          </div>

          <div className="input-section">
            <span>E-mail</span>
            <input 
            type="text" 
            name="email" 
            value={email} 
            onChange={e => setEmail(e.target.value)} 
            placeholder="Digite seu e-mail" 
            required
            />
          </div>

          <div className="input-section">
            <span>Senha</span>
            <input 
            type="password" 
            name="password" 
            value={password} 
            onChange={e => setPassword(e.target.value)} 
            placeholder="Informe uma senha"
            minlength="6"
            required
            />
            {passwordValidate !== '' && <p className="validation">{passwordValidate}</p>}
          </div>

          <div className="input-section">
            <span>Nome da Empresa</span>
            <input 
            type="text" 
            name="company_name" 
            value={companyName} 
            onChange={e => setCompanyName(e.target.value)} 
            placeholder="Digite o nome da empresa" 
            required
            />
          </div>

          <div className="input-section">
            <span>CPF</span>
            <InputMask 
            mask="999.999.999-99" 
            type="text" 
            name="document" 
            value={document} 
            onChange={e => setDocument(e.target.value)} 
            placeholder="Digite o seu CPF" 
            required
            />
          </div>

          <div className="input-section">
            <span>Plano</span>

            <select 
              name="plan" 
              value={plan}
              onBlur={(e)=> {return e}}
              onChange={(e) => {setPlan(parseInt(e.target.value))}}>
                { plans.map((plan) => (
                  <option value={plan.id}>{plan.title} - R${plan.price},00</option>
                ))}
            </select>

          </div>

          <div className="terms">
            <input type="checkbox" checked={isChecked} onChange={(e) =>  setIsChecked(e.target.checked)} />
            <p>Declaro ter lido e aceito os </p>
            <a target="_blank" href="/termos">termos de serviço e políticas de privacidade.</a>
          </div>
          
          <button type="button" onClick={insert}>Assinar</button>
        </form>
      </ContactFormSection>
    </Wrapper>
  );
}

export default LegalClient;